import React from "react";
import "./footer.css"
import STARKEN_LOGO_WHITE from '../../../assets/logo/Starken-White.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faVoicemail } from "@fortawesome/free-solid-svg-icons";


export default function Footer() {
    return (
        <>
            <footer>
                <div className='footer-container'>
                    <div className="container-fluid footer">
                        <div className="row" style={{placeContent:'center', textAlign:'center'}}>
                            <div className="col-md-12 col-xl-5">
                                <img className="img" src={STARKEN_LOGO_WHITE} />
                            </div>

                        </div>
                        <div className="row" style={{ placeContent: 'center' }}>
                            <div className="col-md-12 col-xl-4">

                                <div className="text">
                                    <h2 style={{ textAlign: 'center' }}>CONT&Aacute;CTANOS</h2>  <h5>

                                        <div className="row" style={{ placeContent: 'center' }}>
                                            <div className="col-3" style={{ textAlignLast: 'right' }}>
                                                <FontAwesomeIcon icon={faMailBulk} />
                                            </div>
                                            <div className="text col-6">
                                                <h4>konectados@starken.cl</h4>
                                            </div>
                                        </div>

                                    </h5>
                                </div>


                            </div>
                            <div className="text">
                                © Starken {new Date().getFullYear()}
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}