import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Index from './pages/Home';
import Error404 from './pages/Error404/error';
import Header from './components/ui/header/header';
import Footer from './components/ui/footer/footer';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <>
              <Header />
              <Index />
              <Footer />
            </>
          } />
          <Route path='*' element={
            <>
              <Error404 />
            </>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
