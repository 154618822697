import React from 'react';
import "./header.css";
import STARKEN_LOGO_GREEN from '../../../assets/logo/Starken-Green.png';

export default function Header() {
    return (
        <>
            <header>
                <div className='container-fluid header'>
                    <div className='row'>
                        <div className='col-12 col-md-12 col-xl-4 '>
                            <div>
                                <img className='img'
                                src={STARKEN_LOGO_GREEN}
                                alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}