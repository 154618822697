import React from "react";
import './index.css';
import Formulario from "../../components/form/Form_Home/mainForm";

export default function Index(props: any) {
    return (
        <>
            <div className="bg-with-img">

                <div className="container align-middle">
                    <div className="row">
                        <h1 className="p-5 title">¡Queremos StarKonectados!</h1>
                        <div className="col-xl-6 center-align">
                            <div className="p-5 w-100 text green-bg" >
                                <p>Te invitamos a unirte a nuestro Whatsapp Starkonectados. A trav&eacute;s de este canal de comunicaci&oacute;n podr&aacute;s
                                    informarte de las noticias m&aacute;s relevantes de nuestra empresa, los beneficios a los cuales podr&aacute;s acceder y todo lo que
                                    aporte para estar m&aacute;s conectados como equipo.</p>
                                <p>Es un canal unidireccional por lo que no responderemos mensajes por esta v&iacute;a, pero siempre te diremos d&oacute;nde
                                    comunicarte en caso de dudas o consultas.</p>
                                <p>&iexcl;&Uacute;nete a nuestra comunidad dejando tus datos aqu&iacute;!</p>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <Formulario />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}