import React from "react";

export default function Error404() {
    return (
        <>
            <div className="page-wrap d-flex flex-row align-items-center">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-12 text-center">
                            <span className="display-1 d-block">404</span>
                            <div className="mb-4 lead">La pagina que estas buscando no ha sido encontrada.</div>
                            <a href={window.location.origin} className="btn btn-link">Volver al inicio</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}