import axios, {AxiosResponse} from 'axios';

export interface HttpProperies {
    headers : {
        'Access-Control-Allow-Origin'?: string,
        'Access-Control-Allow-Methods'?: string,
        'Access-Control-Allow-Headers'?: string,
        'Content-Type'?: string,
        Authorization?: string,
        [key: string]: any
    }
}


export class Api<T> {

    protected readonly baseUrl: string;
    protected readonly httpOptions: HttpProperies;

    constructor(baseUrl: string, httpOptions: HttpProperies) {
        this.baseUrl = baseUrl;
        this.httpOptions = httpOptions;
        this.httpOptions.headers['Access-Control-Allow-Origin'] = '*';
        this.httpOptions.headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token';
        this.httpOptions.headers['Access-Control-Allow-Methods'] ='GET, POST, PATCH, PUT, DELETE, OPTIONS';
        this.httpOptions.headers['Content-Type']= 'application/json';
    }

    isNetworkError = (err: any) => {
        return !!err.isAxiosError && !err.response;
    }



    async get(url:string): Promise<T> {
        const response: AxiosResponse = await axios.get(`${this.baseUrl}${url}`, this.httpOptions);
        return response.data;
    }

    async post(endpoint: string, entity: T): Promise<T> {
        const response: AxiosResponse = await axios.post(`${this.baseUrl}${endpoint}`, entity, this.httpOptions);
        return response.data;
    }

}