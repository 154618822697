import './mainForm.css'
import React, { useState } from 'react';
import { ValidateRut } from '../../../utils/validate';
import { useFormik } from 'formik'
import { Button, Modal } from 'react-bootstrap';

import { Starkonectados } from '../../../api/services/starkonectados';
import SimpleModal from '../modals/simpleModal';
import Swal from 'sweetalert2';


export default function Formulario() {


    const validations = (values: any) => {
        let errors: any = {};

        if (!values.nombres) {
            errors.nombres = 'El nombre es requerido';
        }

        if (!values.apellidos) {
            errors.apellidos = 'El apellido es requerido';
        }


        if (!ValidateRut.rut(values.rut)) {
            errors.rut = 'El rut es invalido';
        }

        if (!values.rut) {
            errors.rut = 'El rut es requerido';
        }

        if (!ValidateRut.phoneValidator(values.telefono)) {
            errors.telefono = 'El número es invalido';
        }

        if (!values.telefono) {
            errors.telefono = 'El número es requerido';
        }


        if (!ValidateRut.emailValidator(values.correo)) {
            errors.correo = 'El correo es invalido';
        }

        if (!values.correo) {
            errors.correo = 'El correo es requerido';
        }

        return errors;

    }

    const formik: any = useFormik({
        initialValues: {
            nombres: '',
            apellidos: '',
            rut: '',
            telefono: ''
        },
        validate: validations,
        onSubmit: async (values: any) => {

            let rut = values.rut.replaceAll(".", '').replaceAll("-", '');
            values.rut = rut
            await new Starkonectados().postForm(values)
                .then((res: any) => {
                    Swal.fire({
                        icon: 'success',
                        title: '¡Nos alegra que quieras  starkonectad@!',
                        text: 'Próximamente comenzarás a recibir nuestras novedades, beneficios e información de interés.'
                    }
                    )
                })
                .catch((err: any) => {

                    if (err.response !== undefined) {
                        Swal.fire(
                            {
                                title: 'Error',
                                text: err.response.data.error,
                                icon: 'error'
                            }
                        )
                    } else {
                        Swal.fire(
                            {
                                title: 'Error',
                                text: 'Ocurrio un error inesperado.',
                                icon: 'error'
                            }
                        )

                    }
                })
        }
    });

    return (
        <> <div>
            <section
                className="p-5 w-100 appear"
                style={{ borderRadius: '.5rem .5rem 0 0' }}
            >
                <div className="row ">
                    <div className="col-xl-12">
                        <div className="card text-black">

                            <div className="card-body p-md-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-6 col-xl-12 order-2 order-lg-2">
                                        <p className="text-center h1 fw-bold mb-5 mt-4">FORMULARIO</p>
                                        <form onSubmit={formik.handleSubmit}>
                                            <div className='row'>

                                                <div className='col-xl-6 text-left'>
                                                    <label htmlFor='nombres' className='form-label'>Nombre</label>
                                                    <input
                                                        id='nombres'
                                                        name='nombres'
                                                        type='text'
                                                        placeholder='Nombre'
                                                        className='form-control'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.nombres}
                                                    />
                                                    {formik.errors.nombres ? (
                                                        <small className='text-danger mt-1'>
                                                            {formik.errors.nombres}
                                                        </small>
                                                    ) : null}

                                                </div>

                                                <div className='col text-left'>
                                                    <label htmlFor='apellidos' className='form-label'>Apellido</label>
                                                    <input
                                                        id='apellidos'
                                                        name='apellidos'
                                                        type='text'
                                                        placeholder='Apellido'
                                                        className='form-control'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.apellidos}
                                                    />
                                                    {formik.errors.apellidos ? (
                                                        <small className='text-danger mt-1'>
                                                            {formik.errors.apellidos}
                                                        </small>
                                                    ) : null}
                                                </div>

                                            </div>

                                            <div className='row'>
                                                <div className='col-xl-6 text-left'>
                                                    <label htmlFor='rut' className='form-label'>Rut</label>
                                                    <input
                                                        id='rut'
                                                        name='rut'
                                                        type='text'
                                                        placeholder='11.111.111-1'
                                                        className='form-control'
                                                        onChange={formik.handleChange}
                                                        value={ValidateRut.runFormat(formik.values.rut)}
                                                    />
                                                    {formik.errors.rut ? (
                                                        <small className='text-danger mt-1'>
                                                            {formik.errors.rut}
                                                        </small>
                                                    ) : null}

                                                </div>
                                                <div className='col text-left'>
                                                    <label htmlFor='telefono' className='form-label'>N&uacute;mero</label>
                                                    <input
                                                        id='telefono'
                                                        name='telefono'
                                                        type='text'
                                                        placeholder='+569123456789'
                                                        className='form-control'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.telefono}

                                                    />
                                                    {formik.errors.telefono ? (
                                                        <small className='text-danger mt-1'>
                                                            {formik.errors.telefono}
                                                        </small>
                                                    ) : null}

                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-xl-12 text-left'>
                                                    <label htmlFor='correo' className='form-label'>Correo</label>
                                                    <input
                                                        id='correo'
                                                        name='correo'
                                                        type='text'
                                                        placeholder='correo@correo.cl'
                                                        className='form-control'
                                                        onChange={formik.handleChange}
                                                        value={formik.values.correo}
                                                    />
                                                    {formik.errors.correo ? (
                                                        <small className='text-danger mt-1'>
                                                            {formik.errors.correo}
                                                        </small>
                                                    ) : null}

                                                </div>

                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col text-right actionButtons '>
                                                    <Button
                                                        variant='success'
                                                        onClick={formik.handleSubmit}
                                                    >
                                                        Enviar mensaje
                                                    </Button>
                                                </div>
                                            </div>


                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </div >

        </>
    );
}