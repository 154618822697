import { Api, HttpProperies } from "../api";



export  class Starkonectados {

    private TOKEN: string = process.env.REACT_APP_API_KEY_STARKONECTADOS as string;
    private config: HttpProperies;
    private ENDPOINT:string = process.env.REACT_APP_API_STARKONECTADOS as string;
    

    constructor (){
        //agregar el token a esta api
        this.config = {
            headers: {
                'x-auth-token': this.TOKEN
            }
        }
    }

    async getTest(): Promise<any> {
        return new Api(this.ENDPOINT, this.config ).get('starkonectado/test');

    }

    async postForm(body: any): Promise<any> {
        return new Api(this.ENDPOINT, this.config).post('starkonectado/registro', body);
    }
}