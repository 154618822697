/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
//Validador de rut de StarkenCl

export const ValidateRut = {
  rut: function (rutCompleto: string) {
    try {
      rutCompleto = rutCompleto.replace("‐", "-");
      rutCompleto = rutCompleto.split("-").join("");
      rutCompleto = rutCompleto.split(".").join("");
      rutCompleto =
        rutCompleto.substr(0, rutCompleto.length - 1) +
        "-" +
        rutCompleto.substr(rutCompleto.length - 1, 1);
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) {
        return false;
      }

      var tmp = rutCompleto.split("-");
      var digv = tmp[1];
      var rut = parseInt(tmp[0], 10);
      if (digv == "K") digv = "k";

      return ValidateRut.dv(rut) == digv;
    } catch {
      return false;
    }
  },
  dv: function (T: number) {
    var M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  },
  runFormat: function (run: string): string {
    if (run.length > 1) {
      run = run.split("-").join("").split(".").join("");
      const dv = run.substr(run.length - 1, 1);
      const rut = parseInt(run.substr(0, run.length - 1));
      if (isNaN(rut)) {
        return run;
      }
      if (rut <= 0) {
        return run;
      }
      let num = rut
        .toString()
        .split("")
        .reverse()
        .join("")
        .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
      num = num.split("").reverse().join("").replace(/^[\.]/, "");
      return num.toString().concat("-").concat(dv.toUpperCase());
    }
    return run;
  },
  phoneFormat: function (values: number): number {
    let value= values.toString();
    var out = value.replace(/\s/g, "");
    let num = out.toString().split("").slice(0,9);
    if (num.length > 1) {
      num.splice(1, 0, " ");
    }
    if (num.length > 6) {
      num.splice(6, 0, " ");
    }
    value = num.join("");
    return values;
  },

  phoneValidator: function (number: number) :boolean {
      const regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,5}$/im;
      const regex = new RegExp(regExp);

      const validation = regex.test(number.toString());
      return validation;
  },

  emailValidator: function (correo: string) : boolean {
    const regExp = /^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const regex = new RegExp(regExp);

    return regex.test(correo);
  }
};
